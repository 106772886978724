<template>
    <input
        :id="name"
        :name="name"
        :type="type"
        :value="value"
        class="form-input block w-full rounded-md transition inputField"
        @input="$emit('input', $event.target.value)"
    >
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            default: 'text'
        },

        name: {
            type: String,
            required: true
        },

        value: {
            type: [String, Number],
            default: null
        }
    }
};
</script>

<style lang="postcss" scoped>
.inputField {
    padding: 1rem 1.5rem;
    &:focus {
        border-color: var(--primaryColor);
    }
}
</style>
