import * as API from '../API.js';

export default {
    all () {
        return API.apiClient.get('/admin/teachers');
    },

    get (teacherId) {
        return API.apiClient.get(`/admin/teachers/${teacherId}`);
    },

    create (payload) {
        return API.apiClient.post('/admin/teachers', payload);
    },

    update (teacherId, payload) {
        return API.apiClient.put(`/admin/teachers/${teacherId}`, payload);
    },

    remove (teacherId) {
        return API.apiClient.delete(`/admin/teachers/${teacherId}`);
    }
};
