<template>
    <div>
        <AdminTop
            :heading="teacherId ? 'Redigera lärare' : 'Skapa lärare'"
        />

        <BaseForm
            v-if="teacher"
            :loading="loading"
            :submit-label="teacherId ? 'Uppdatera' : 'Lägg till'"
            @submit="handleSubmit"
        >
            <FormGroup
                label="Bild"
                name="image"
            >
                <ImageUpload
                    :image="teacher.image"
                    @change="image => teacher.image = image"
                />
            </FormGroup>

            <FormGroup
                label="Namn"
                name="name"
            >
                <InputField
                    v-model="teacher.name"
                    name="name"
                    type="text"
                    required
                />
            </FormGroup>

            <FormGroup
                label="E-post"
                name="email"
            >
                <InputField
                    v-model="teacher.email"
                    name="email"
                    type="email"
                    required
                />
            </FormGroup>

            <FormGroup
                label="Telefonnummer"
                name="phone"
            >
                <InputField
                    v-model="teacher.phone"
                    name="phone"
                    type="text"
                    required
                />
            </FormGroup>
        </BaseForm>
    </div>
</template>

<script>
import AdminTop from '@/components/ui/AdminTop';
import BaseForm from '@/components/form/BaseForm';
import FormGroup from '@/components/form/FormGroup';
import InputField from '@/components/form/InputField';
import ImageUpload from '@/components/form/ImageUpload';
import TeacherService from '@/services/admin/TeacherService';

export default {
    components: {
        AdminTop,
        BaseForm,
        FormGroup,
        InputField,
        ImageUpload
    },

    data: () => ({
        loading: false,
        imagePreview: null,
        teacher: null
    }),

    computed: {
        teacherId () {
            return this.$route.params.id;
        }
    },

    async created () {
        this.teacher = await this.getTeacher();
    },

    methods: {
        async getTeacher () {
            if (!this.teacherId) {
                return {
                    name: null,
                    email: null,
                    phone: null,
                    image: null
                };
            }

            const res = await TeacherService.get(this.$route.params.id);

            return res.data;
        },

        async handleSubmit () {
            this.loading = true;

            try {
                if (this.teacherId) {
                    await this.update();
                } else {
                    await this.create();
                }
            } catch (error) {
                // eslint-disable-next-line
                console.log(error);
            }

            this.loading = false;
        },

        async create () {
            const res = await TeacherService.create(this.teacher);

            if (res.status === 200 && res?.data?.teacher?.id) {
                this.$router.push(`/admin/teachers/${res.data.teacher.id}`);
            }
        },

        async update () {
            return await TeacherService.update(this.teacherId, this.teacher);
        }
    }
};
</script>
