<template>
    <form
        class="baseForm whiteBox whiteBox--padding m-auto mt-8"
        @submit.prevent="!loading ? $emit('submit') : null"
    >
        <slot />

        <div class="flex justify-end mt-10">
            <Button
                :loading="loading"
                :label="submitLabel"
                type="submit"
                class="max-w-xs"
            />
        </div>
    </form>
</template>

<script>
import Button from '@/components/ui/Button';

export default {
    components: {
        Button
    },

    props: {
        submitLabel: {
            type: String,
            required: true
        },

        loading: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style>
.baseForm.whiteBox--padding {
    @media (min-width: 1451px) {
        padding: 4rem 4.5rem;
    }
}
</style>
