<template>
    <div class="imageUpload">
        <div class="inner">
            <v-file-input
                :value="image ? { url: image } : null"
                accept="image/*"
                :prepend-icon="null"
                outlined
                dense
                @change="handleImageUpload"
                @click:clear="clear"
            >
                <template v-slot:selection>
                    <div>
                        <img
                            v-if="imagePreview"
                            :src="imagePreview"
                            class="imagePreview ab100 objectFitCover"
                        >
                    </div>
                </template>

                <template v-slot:prepend-inner>
                    <UploadIcon
                        v-if="!imagePreview"
                        class="uploadIcon text-gray-600"
                    />
                </template>
            </v-file-input>
        </div>
    </div>
</template>

<script>
import UploadIcon from '@/assets/images/upload.svg';
import * as API from '@/services/API.js';

export default {
    components: {
        UploadIcon
    },

    props: {
        image: {
            type: String,
            default: null
        }
    },

    data: () => ({
        imagePreview: null
    }),

    mounted () {
        this.imagePreview = this.image;
    },

    methods: {
        clear () {
            this.imagePreview = null;
            this.$emit('change', null);
        },

        async handleImageUpload (image) {
            if (image && image !== undefined && image.size) {
                const res = await this.uploadFile(image);
                this.imagePreview = res.data;
                this.$emit('change', this.imagePreview);
            }
        },

        async uploadFile (file) {
            const formData = new FormData();
            formData.append('file', file);

            const res = await API.apiClient.post('/admin/media', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            return res;
        }
    }
};
</script>

<style lang="postcss" scoped>
.imageUpload >>> .v-text-field__details {
    display: none
}

.imageUpload >>> fieldset {
    border: 0;
}

.imageUpload {
    width: 20rem;
    max-width: 100%;
}

.inner {
    border: 1px solid #e2e8f0;
    border-radius: 0.375rem;
    overflow: hidden;
}

.imageUpload >>> .v-input__slot {
    height: 20rem;
    padding: 0 !important;
    margin-bottom: 0;
}

.imagePreview {
    width: 20rem;
    max-width: 20rem;
}

.imageUpload >>> .mdi-close {
    color: transparent;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: var(--secondaryColor);
    margin-top: -2.4rem;
    &:hover {
        background-color: var(--primaryColor)
    }
    &::before,
    &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        height: 16px;
        width: 2px;
        margin-top: -8px;
        margin-left: -1px;
        background-color: #fff;
        opacity: 1;
        border-radius: 0;
    }
    &::before {
        transform: rotate(45deg);
    }
    &::after {
        transform: rotate(-45deg);
    }
}

.imageUpload >>> .v-input__append-inner {
    margin-top: 0 !important;
}

.uploadIcon {
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -1.5rem;
    margin-top: -1.5rem;
}
</style>
